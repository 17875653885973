<template>
    <!-- Remove the container if you want to extend the Footer to full width. -->
<div class="container-fluid">
    <!-- Footer -->
    <footer
            class="text-center text-lg-start text-white"
            style="background-color: #3e4551"
            >
      <!-- Grid container -->
      <div class="container p-4 pb-0">
        <!-- Section: Links -->
        <section class="">
          <!--Grid row-->
          <div class="row">
            <!--Grid column-->
            <div class="col-lg-4 col-md-6 mb-4 mb-md-0">
              <h5 class="text-uppercase">Connect2Deen</h5>
  
              <p>
                Connect2Deen is a service to the international community
                by Mawlana Sajid Irshad Sahib on regards to a mass variety
                of issues which can be found in his various topics from
                Pearls of Wisdom to the weekly Jum'uah lectures and other 
                categories. Such audios are free for use in videos and audios 
                so long as credit is given by the mentioning of this website or it's 
                social media platforms
              </p>
            </div>
            <!--Grid column-->
  
           
  
          
  
           
          </div>
          <!--Grid row-->
        </section>
        <!-- Section: Links -->
  
        <hr class="mb-4" />
  
  
        <hr class="mb-4" />
  
        <!-- Section: Social media -->
        <section class="mb-4 text-center">
          <!-- Facebook -->
          <a
             class="btn btn-outline-light btn-floating m-1"
             href="https://www.facebook.com/profile.php?id=61552836236172"
             role="button"
             target="_blank"

             ><i class="fab fa-facebook-f"></i
            ></a>
  
            <a
            class="btn btn-outline-light btn-floating m-1"
            href="https://www.tiktok.com/@connect2deen"
            role="button"
            target="_blank"

            ><i class="fab fa-tiktok"></i
           ></a>
 
         
  
          <!-- Instagram -->
          <a
             class="btn btn-outline-light btn-floating m-1"
             href="https://www.instagram.com/connect2deen2023/?igshid=OGQ5ZDc2ODk2Za%3D%3D"
             role="button"
             target="_blank"
             ><i class="fab fa-instagram"></i
            ></a>
  
          
        </section>
        <!-- Section: Social media -->
      </div>
      <!-- Grid container -->
  
      <!-- Copyright -->
      <div
           class="text-center p-3"
           style="background-color: rgba(0, 0, 0, 0.2)"
           >
        © 2023 Copyright: Connect2Deen
        <br/>
        <router-link to="/policy"><u>Privacy Policy</u></router-link>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
      </div>
      <!-- Copyright -->
    </footer>
    <!-- Footer -->
  </div>
  <!-- End of .container -->
</template>

<script>

</script>

<style scoped>

</style>
<template>
    <Header/>
    <div class="container">
        <br />
        <h2><u>Privacy Policy</u></h2>
        <br />
        <h2>Information Collection</h2>
        <p>We do not collect any personally identifiable information from our users. You are free to browse our website and listen to Islamic sermons without providing any personal data.</p>
        
        <h2>Advertisements</h2>
        <p>We use Google AdSense to serve advertisements on our website. Google AdSense uses cookies to deliver targeted ads based on your visits to our site and other sites on the Internet. These cookies do not contain any personally identifiable information. For more information on how Google uses data when you use our website, please visit <a href="https://www.google.com/policies/privacy/partners/" target="_blank">Google's Privacy & Terms</a>.</p>
        
        <h2>Cookies</h2>
        <p>We may use cookies to enhance your browsing experience. These cookies are small text files stored on your device that help us analyze website usage and improve our services. Cookies do not contain personal information.</p>
        <p>You can disable cookies through your browser settings if you prefer not to receive them. Please note that disabling cookies may affect your experience on our website. To manage your preferences for Google AdSense cookies, please visit <a href="https://adssettings.google.com/" target="_blank">Google Ad Settings</a>.</p>
        
        <h2>External Links</h2>
        <p>Our website may contain links to external sites. We are not responsible for the content or privacy practices of these sites. Please review the privacy policies of external websites before providing any personal information.</p>
        
        <h2>Changes to This Privacy Policy</h2>
        <p>This Privacy Policy is effective as of 07/02/2024 and may be updated from time to time. Any changes will be posted on this page. Your continued use of our website after modifications constitutes acceptance of the updated Privacy Policy.</p>
        
        <h2>Contact Us</h2>
        <p>If you have any questions or concerns about our Privacy Policy, please contact us at connect2deen@outlook.com</p>
        <br/>
        <br/>
    </div>

    <Footer/>
</template>

<script>
import Header from '../components/Header'
import Footer from '../components/Footer'

export default{
    components:{
        Header,
        Footer
    }
}
</script>

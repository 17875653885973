<template>
    <div class="container info-home">
      <div class="info-home-title-cont">
              <img class="mr-2" src="../assets/icons/logo.png" width="50" height="50">
        <h3>Today's Pick</h3>
      </div>

        <p id="date">{{this.date}} / {{this.mm}} / {{this.year}}&emsp;{{this.iD}} / {{this.iM}} / {{this.iY}}</p>

        <div class="announcements">
            <h4 style="padding: 5px; display: block;">Announcements</h4>
            <p class="ml-2">Darse of Surah Ahzab and Baqarah have been added and updated</p>
            <div class="images">
                <img src="../assets/images/reminder.jpg" alt="">
                <img src="../assets/images/sisters.jpg" alt="">
    
            </div>

            <br />

            <i class="fab fa-youtube" style="font-size: 40px; margin: 10px; margin-left: 20px;" @click="newTab('https://www.youtube.com/channel/UCELXbRWfJI9lhbEqtvrf0mA')"></i>
            <a href="mailto:connect2deen@outlook.com" class="btn btn-primary" id="contact">contact us</a>

        </div>
    
        <div class="container bg-light info">
            <br/>
            <p>Mawlana Sajid Irshad completed his Alimiyyah course from Al Jamiatul Islamiyah Darul Uloom Bolton in 2005</p>
            <p>Since graduation he has been delivering lectures around various topics in both English and Urdu on spirituality, commentry of the Quran Shareef and contemporary issues dealing with the society</p>
            <p>The talks are easy to understand and thought provoking which encourage the listener to remain attentive and empower one to connect to the deen </p>
            <p>Currently he is an Imam at Masjid Farooqe Azam Burnley, a teacher of Islamic studies at Rawdhatul Uloom school and a senior lecturer of hadeeth at Al Barakah Academy</p>
        <br/>
        </div>

        <br/>
        <br/>

        
    </div>
</template>

<script>
import {
    useGregorianDate
} from '../composables/useGregorianDate.js'
import {
    useArabDate
} from '../composables/useArabDate'

export default{
    methods:{
        newTab(url){
            window.open(url, "_blank");

        },
    },
    setup(){
      var {
            date,
            mm,
            year
        } = useGregorianDate()
        var {
            iD,
            iM,
            iY
        } = useArabDate()

        return{
          date,
            mm,
            year,
            iD,
            iM,
            iY,
        }
    }
}
</script>

<style scoped>
.info-home{
    margin-top: 20px;
    min-height: 100vh;
}
.announcements{
    border-radius: 5px; 
    border-left:5px solid #6D9886; 

    background-color: white;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  }
  .announcements ul li p{
    color: #333;
  
  }
  .announcements ul{
    list-style-type:circle;
  }
  .fab:hover{
    cursor: pointer;
  }
  #contact{
    margin-bottom: 15px;
  }
  .info{
    margin-top: 20px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  }

  .images{
    display: grid;
    grid-template-columns: 1fr 1fr;
    text-align: center;
    justify-content: center;
  }

  .images img{
    max-width: 525px;
    margin: auto;
  }

  @media only screen and (max-width: 768px) {
    .images{
        grid-template-columns: 1fr;
    }
    .images img{
        max-width: 300px;
    }
  }

.info-home-title-cont{
  display: inline-flex;

}
.info-home-title-cont h3{
  margin: auto;
}
</style>